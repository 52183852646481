.contact-container {
    width: 70%;
    max-width: 450px;
    margin: 1rem auto;
    font-family: 'Lora', sans-serif; /* Ensure Lora is applied to the entire container */
    font-weight: 400px;
    padding: 1.5rem; /* Constant padding around the form */
    background-color: #fcfaf4;
    border: 1px solid #2b2d2b;
    border-radius: 5px;
    position: relative;
  }
  
  .contact-content {
    padding: '1rem';
    min-height: 400px; /* Ensures that the container keeps a height to prevent CLS */
    font-family: 'Lora', sans-serif; /* Lora font applied to the content */
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    font-family: 'Lora', sans-serif; /* Lora font applied to the form */
  }
  
  .form-label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #2b2d2b;
    font-family: 'Lora', sans-serif; /* Lora font for labels */
  }
  
  .form-input, .form-textarea {
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Lora', sans-serif; /* Lora font for input and textarea */
  }
  
  .form-textarea {
    height: 100px;
    resize: vertical;
  }
  
  .submit-btn {
    padding: 0.75rem 1.5rem;
    background-color: #2b2d2b;
    color: #fcfaf4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    font-family: 'Quicksand', sans-serif; /* Lora font for submit button */
  }
  
  .submit-btn:hover {
    background-color: #8fbc8f;
  }
  
  .checkbox-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    font-family: 'Lora', sans-serif; /* Lora font for checkbox group */
  }
  
  .thank-you-message {
    font-size: 1.75rem;
    color: #2b2d2b;
    text-align: center;
    padding: 2rem;
    background-color: #fcfaf4;
    border: 1px solid #2b2d2b;
    border-radius: 5px;
    font-family: 'Lora', sans-serif; /* Lora font for thank you message */
  }