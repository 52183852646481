.container {
  display: flex;
  flex-direction: row;
  min-height: 50vh;
  background-color: #fcfaf4;
}

.heroSection {
  flex: 1;
  padding: 100px;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  font-family: 'Lora', sans-serif;
  box-sizing: border-box;
}

/* Sidebar with a distinct background and padding */
.sidebar {
  min-width: 150px;
  background-color: #8FBC8F;
  padding: 10px 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  width: 1.25px;
  height: 25%; /* Adjust height as needed */
  background-color: #FCFAF4; /* Light color for the line */
}

.smallText {
  font-size: 24px;
  font-style: italic; /* This will make the text italic */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.01);
  letter-spacing: 0.05em;
  
  margin-bottom: 10px;
  color: #2b2d2b;
  font-family: 'Lora', sans-serif;
  transition: color 0.3s ease, transform 0.3s ease;
}

.smallText:hover {
  color: #ffd700; /* Yellow on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Headings with varying font sizes */
.container .heading {
  font-size: 20px; /* Explicitly set size for large screens */
  line-height: 1.5;
  text-align: left;
  font-family: 'Quicksand', sans-serif; /* Apply Georgia font */
  font-weight: 400;
  color: #333;
}

/* Adjust heading size at breakpoints */
@media (max-width: 1200px) {
  .heading {
    font-size: 1.75rem; /* Slightly smaller for screens less than 1200px wide */
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 1.5rem; /* Smaller for screens less than 992px wide */
  }
}

@media (max-width: 808px) {
  .heading {
    font-size: 1.5rem; /* Even smaller for screens less than 808px wide */
  }
}

/* Stack the sidebar below the hero section on small screens */
@media (max-width: 868px) {
  .container {
    flex-direction: column;
  }
  .heroSection {
    padding: 50px 20px; /* Reduce padding to prevent text from being too skinny */
    max-width: 90%; /* Expand max-width to better fit screen space */
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.25rem; /* Smallest size for screens less than 576px wide */
  }
  .heroSection {
    padding: 30px 15px; /* Further reduce padding on smaller screens */
    max-width: 95%; /* Further expand max-width to utilize screen space */
  }
}